<template>
    <div class="app-container">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>{{ $t('comm.questions.questions-header') }}</span>
            </div>
            <el-pagination class="center" layout="prev, pager, next, total" :current-page="currentPage" :total="count"
                :page-size="limit" @current-change="changePage">
            </el-pagination>
            <div class="mini-separator"></div>
            <el-table :data="questions" border style="width: 100%" size="mini">
                <el-table-column prop="id" :label="$t('comm.questions.unique-id')" width="80"> </el-table-column>
                <el-table-column prop="nume" :label="$t('comm.questions.name')"> </el-table-column>
                <el-table-column :label="$t('comm.questions.product-name')">
                    <template slot-scope="scope">
                        <el-link type="primary" :underline="false">
                            <a :href="scope.row.products_url" target="_blank">{{ scope.row.products_name }}</a>
                        </el-link>
                    </template>
                </el-table-column>
                <el-table-column prop="products_model" :label="$t('comm.questions.product-model')"> </el-table-column>
                <el-table-column :label="$t('comm.questions.status')" width="120" align="center">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.resolved" type="success">
                            {{ $t('comm.questions.resolved-1') }}
                        </el-tag>
                        <el-tag v-else type="danger">
                            {{ $t('comm.questions.resolved-0') }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="data" :label="$t('comm.questions.date')" width="140" align="center"></el-table-column>
                <el-table-column :label="$t('comm.questions.view')" fixed="right" width="105" align="center">
                    <template slot-scope="scope">
                        <router-link :to="{ path: `/comunicare/intrebare_produs/${scope.row.id}` }">
                            <el-button
                                class="btn-click-gotoaddress"
                                size="mini"
                                type="primary"
                                icon="el-icon-info"
                                plain
                                >{{ $t('general.details') }}
                            </el-button>
                        </router-link>
                    </template>
                </el-table-column>
            </el-table>
            <div class="mini-separator"></div>
            <el-pagination class="center" layout="prev, pager, next, total" :current-page="currentPage" :total="count"
                :page-size="limit" @current-change="changePage">
            </el-pagination>
        </el-card>
    </div>
</template>

<script>
import { getProductsQuestions } from '@/api/comunicare';
import has from 'lodash.has';

export default {
    data() {
        return {
            questions: [],
            count: 0,
            currentPage: 1,
            limit: 50,
            start: 0
        };
    },
    methods: {
        GetProductsQuestions() {
            const params = { start: this.start, withCount: true };
            getProductsQuestions(params).then((res) => {
                if (!has(res, 'message')) return false;
                if (parseInt(res.error) > 0) return false;
                this.questions = res.message.data;
                this.count = res.message.count
            });
        },
        changePage(page) {
            this.start = page * this.limit - this.limit;
            this.currentPage = page;
            this.GetProductsQuestions();
		}
    },
    mounted() {
        this.GetProductsQuestions();
    }
};
</script>
